import React, { Component } from 'react';
import Counter from  '../../components/adds/counter';

class Content extends Component{
    render(){
        return(
            <content>
                <div className="container-fluid">
                
                    <div>
                        <Counter />
                    </div>
                    
                </div>
            </content>
        );
    }
}

export default Content;