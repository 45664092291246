import React, { Component } from 'react';
import './Assets/style/default.css';

// components
import Header from  './components/headerComponent/header';
import Content from  './components/contentComponent/content';
import Footer from  './components/footerComponent/footer';

class App extends Component{
  render(){
    return(
      <div className="App">
        <Header />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default App;