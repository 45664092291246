import React, { Component } from 'react';

class Header extends Component{
    render(){
        return(
            <header>
                <div className="logo">
                    <a href="http://www.nrdzk.de">nrdzk.de</a>
                </div>

                <nav>
                    <ul>
                        <li><a href="http://www.nrdzk.de">first</a></li>
                        <li><a href="http://www.nrdzk.de">secound</a></li>
                        <li className="last"><a href="http://www.nrdzk.de">third</a></li>
                    </ul>
                </nav>
            </header>
        );
    }
}

export default Header;