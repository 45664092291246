/*
// Set the date we're counting down to
var countDownDate = new Date("Feb 20, 2020 22:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="counter"
    document.getElementById("counter").innerHTML = "Nur noch:  <br><br>" + days + " Tage " + hours + " Std. <br>"
    + minutes + " Min. " + seconds + " Sek. ";

    // If the count down is finished, write some text 
    if (distance < 0) {
        clearInterval(x);
        document.getElementById("counter").innerHTML = "EXPIRED";
    }
}, 1000);
*/

import React, { Component } from 'react';

class Counter extends Component{

    constructor(props){
        super(props);
        this.state = 
        {
            countDownDate: new Date("Mar 12, 2020 22:00:00"),

            now: new Date(),
            distance: new Date("Mar 12, 2020 22:00:00") - new Date().getTime(),

            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    componentDidMount(){
        this.ticker = setInterval(()=> this.tick(), 1000);
    }

    componentWillUnmount(){
        clearInterval(this.ticker);
    }

    tick() {
        this.setState({
            distance: this.state.countDownDate.getTime() - new Date().getTime(),

            days: Math.floor(this.state.distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((this.state.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((this.state.distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((this.state.distance % (1000 * 60)) / 1000)
        });
    }


    render(){
        return(
            <div>
                <h1>Count Things</h1>
                <br />
                <h2>Datum: {this.state.now.toLocaleString()}</h2>
                <br />
                <h2>Ende: {this.state.countDownDate.toLocaleString()}</h2>
                <br />
                <h2>Noch: { 
                    this.state.days + " Tag/e " + 
                    this.state.hours + " Stunde/n " + 
                    this.state.minutes + " Minute/n " +
                    this.state.seconds + " Sekunde/n"
                }</h2>
            </div>
        );
    }
}

export default Counter;