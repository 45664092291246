import React, { Component } from 'react';

class Footer extends Component{
    render(){
        return(
            <footer>
                <div className="footer">
                    <ul>
                        <li className="first">Impressum:</li>
                        <li>Maik Nieradzik</li>
                        <li>Wagenfeldstr. 10</li>
                        <li>22307 Hamburg</li>
                        <li><a href="mailto:kontakt@nrdzk.de">Mail</a></li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default Footer;